a {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}

.text__error,
.text__link {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  font-family: 'Hack', monospace;
}

.text__error {
  color: red;
  font-weight: bold;
  margin-top: -35px;
  height: 2rem;
  line-height: 1.8rem;
  font-size: 1.5rem;
}

.text__link {
  margin-top: 5px;
  height: 2rem;
  line-height: 1.25rem;
  font-size: 1rem;
}
