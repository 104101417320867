@import "index";
@import "custom";

$color-mode-type: data;

#root {
  padding-top: 90px;
}

.App {
  min-height: 82vh;
  padding-right: calc(var(--bs-gutter-x) * 1.3);
  padding-left: calc(var(--bs-gutter-x) * 1.3);
}

.span-vh {
  min-height: 82vh;
}

.navbar-brand {
  padding-left: calc(var(--bs-gutter-x) * 1.3);
}

.toggle-theme {
  padding: .25rem calc(var(--bs-gutter-x) * .7) .25rem .833rem;
}

.footer {
  min-height: calc(18vh - 100px);
  background-color: var(--bs-tertiary-bg);
  border-radius: 1rem 1rem 0 0;
}

.document-search-container {
  top: 6rem;
}

.navigation {
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 70%, rgba(250, 250, 250, 1) 100%);
  border-radius: 0 0 1rem 1rem;
  border-bottom: 1px solid var(--bs-border-color);
  z-index: 2000;
}

.navigation .navbar-brand {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.list-group-item.active {
  background-color: transparent;
  --bs-list-group-active-border-color: transparent;
}

.vh-5 {
  height: 5vh;
}

@include media-breakpoint-up(sm) {
  .vh-10-sm {
    height: 10vh;
  }

  .landing-block {
    .text-block {
      h1 {
        font-size: 29px;
      }
    }
  }
}

.breadcrumb-link {
  text-decoration: none;
  color: var(--bs-secondary);
  &:hover, &:focus {
    color: $navbar-light-hover-color;
  }
}

#basic-navbar-nav .nav-link {
  padding: .917rem 1.667rem;
  gap: .667rem;

  &.selected {
    background-color: var(--bs-tertiary-bg);
    border-radius: 1rem;
    color: var(--bs-link-accent-color);
  }
}

.bg-gradient-2 {
  background: linear-gradient(180deg, rgba(33, 33, 33, 0) 0%, rgba(116, 116, 116, 0.04) 100%);
}

.rounded-pane {
  border: 1px solid $list-group-border-color;
  border-radius: calc(var(--bs-border-radius) * 2);
  padding: 2rem 2.286rem;
}

.rounded-pane-top {
  @extend .rounded-pane;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-pane-middle {
  @extend .rounded-pane;
  border-top: none;
  border-radius: 0;
}

.rounded-pane-bottom, .rounded-pane-middle:last-of-type {
  @extend .rounded-pane;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.pane-shadow {
  box-shadow: 0 .167rem .417rem 0 rgba(0, 0, 0, 0.01),
    0 .75rem .75rem 0 rgba(0, 0, 0, 0.01),
    0 1.583rem 1rem 0 rgba(0, 0, 0, 0.01),
    0 2.833rem 1.167rem 0 rgba(0, 0, 0, 0),
    0 4.5rem 1.25rem 0 rgba(0, 0, 0, 0);
}

.nav-tabs.rounded-pane {
  border: none;
  padding: 0;

  + .tab-content .tab-pane {
    @extend .rounded-pane;
    @extend .pane-shadow;
    min-height: 30vh;
  }

  + .tab-content .tab-pane.active:first-child,
  &:has(.nav-item:first-child:hover) + .tab-content .tab-pane {
    border-top-left-radius: 0;
  }

  .nav-link {
    padding: 0.9em 1.3em;
    border-top-left-radius: 1.7em;
    border-top-right-radius: 1.7em;
  }
}

@media screen and (max-width: 1700px) {
  .nav-tabs.rounded-pane + .tab-content .tab-pane {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.insight {
  padding: 0 1.5rem 1.5rem 1.5rem;

  .rounded-pane {
    padding: 0;

    > div:first-child {
      margin: 0;
      border-bottom: 1px solid var(--bs-border-color);
      h5 {
        padding: 1.5rem 1.2rem;
        margin: 0;
      }
    }
  }

  .list-group {
    padding: 0;
    --bs-list-group-border-width: 0;

    .list-group-item-action.link {
      color: var(--bs-body-color);

      &:hover, &:active {
        color: var(--bs-link-accent-color);
        background-color: var(--bs-tertiary-bg);
      }
    }

    .list-group-item {
      padding: .75rem 2rem;
      border-bottom: 1px solid var(--bs-border-color);
      border-radius: 0;
      background-color: transparent;

      .text-secondary {
        font-size: 0.857rem;
        line-height: 1.28rem;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 1.5rem;
        border-bottom-left-radius: calc(var(--bs-border-radius) * 2);
        border-bottom-right-radius: calc(var(--bs-border-radius) * 2);
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.react-icons {
  vertical-align: middle;
  width: 1.2em;
  height: 1.2em;
  margin-top: -.15em;
  margin-right: .375em;
}

.search-icon {
  position: absolute;
  margin-left: .6em;
  margin-top: .6em;
  width: .8em;
  height: .8em;
}

.dropdown-menu {
  padding-top: .833em;
  padding-bottom: .833em;
  border-color: var(--bs-border-color);
  box-shadow: 0 1.417rem 3.167rem 0 rgba(0, 0, 0, 0.1),
    0 5.75rem 5.75rem 0 rgba(0, 0, 0, 0.09),
    0 12.917rem 7.75rem 0 rgba(0, 0, 0, 0.05),
    0 22.917rem 9.167rem 0 rgba(0, 0, 0, 0.01),
    0 35.833rem 10rem 0 rgba(0, 0, 0, 0);
}

.global-search {
  width: 100% !important;
  padding: .667rem 1.667rem !important;
  max-width: 800px;

  .dropdown-menu {
    margin-top: .25rem;
    padding-top: .833rem;
    padding-bottom: .833rem;
    border: 0;

    .dropdown-item {
      padding: .667rem 1.667rem;
    }
  }
}
@include media-breakpoint-down(sm) {
  .global-search {
    max-width: 520px;
  }
}

.global-search-icon {
  position: absolute;
  margin-left: 1em;
  margin-top: 1.125em;
  width: 1.25em;
  height: 1.25em;
}

.search {
  padding-left: 3em;
}

.landing-block {
  background: linear-gradient(to bottom, $body-bg, rgba(37, 108, 237, .06));
  border-radius: 2rem;
  .text-block {
    max-width: 890px;
  }
}

.global-search-start {
  .search {
    position: static;
    padding: .375rem 4.5rem;
    line-height: 4.417rem;
    border: .167rem solid #266ced;
    border-radius: 2rem;
    box-shadow: 0 1.667rem 1rem 0 rgba(37, 108, 237, .03);

    &:focus {
      box-shadow: 0 0 0 .25rem rgba(38, 108, 222, .15);
    }
  }

  .global-search-icon {
    height: 1.667rem;
    width: 1.667rem;
    margin: 2rem 0 0 2rem;
  }
}

.text-truncate-2 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.text-truncate-1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.btn-xsm {
  @include button-size($btn-padding-y-sm / 2, $btn-padding-x-sm / 1.5, $font-size-base * .750, $btn-border-radius-sm);
}

.module-card, .provider-card {
  display: block;
  text-decoration: none;
  cursor: pointer;
  color: var(--bs-body-color);

  &:hover, &:active {
    color: var(--bs-link-accent-color);
    background-color: var(--bs-tertiary-bg);
  }

  .react-icons {
    font-size: 1.2em;
  }
}

.infinite-scroll-component__outerdiv {
  padding-left: 0;
  padding-right: 0;
}

.sidebar {
  @extend .span-vh;
}

.sidebar .list-group-item {
  border-radius: 0;
  border-color: transparent;
  border-width: 1px;
  min-height: 3.333rem;
  align-items: center;

  &:hover {
    background-color: var(--bs-tertiary-bg);
  }

  &:focus {
    background-color: var(--bs-body-bg);
  }

  &.active, &:active {
    background-color: var(--bs-tertiary-bg);
    color: var(--bs-link-accent-color);
    margin-top: 0;

    .check {
      color: var(--bs-primary);
      font-size: 1rem;
    }
  }
}

.list-group-border-0 {
  padding: 0;

  .list-group-item {
    padding: .617rem .388rem;
    border-radius: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }

  .list-group-item:last-child {
    border-bottom-color: transparent;
  }

  .badge {
    font-size: 0.833em;
    font-weight: normal;
  }
}

.list-group-item-action.link {
  color: var(--bs-link-color);

  .badge.bg-tint {
    color: var(--bs-link-color);
  }

  &:hover, &:focus, &:active {
    color: var(--bs-link-accent-color);
    background-color: var(--bs-tertiary-bg);

    .badge.bg-tint, div {
      color: var(--bs-link-accent-color);
    }
  }
}

.overview {
  padding-left: 1rem;
  padding-right: 1rem;

  a, .top-level {
    color: var(--bs-body-color);
    text-decoration: none;
    cursor: pointer;

    &.active, &:hover {
      color: var(--bs-link-accent-color);
    }
  }

  .no-result {
    color: var(--bs-tertiary-color);
    text-decoration: none;
  }

  li:has(> a.active) {
    background-color: var(--bs-tertiary-bg);
  }

  mark {
    padding: 0 0.1em;
    border-radius: 0.2em;
  }
}

.copy-to-clipboard {
  display: block;
  position: relative;
  float: right;
  margin-left: -100pt;
  margin-right: 7pt;
  margin-top: 11pt;
  border: none;
  border-radius: 1rem;
}

.badge.bg-tint {
  --bs-badge-color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
}

.btn.btn-theme {
  background-color: var(--bs-tertiary-bg);
  color: var(--bs-nav-link-color);

  &:hover, &:active {
    background-color: var(--bs-tertiary-bg);
  }
}

.github-image {
  display: inline-block;
}

.provider-logo {
  display: inline-block;
  margin: .2em 1em .2em 0;
  padding: .167rem;
  background-color: white;
  border-radius: .5rem;

  .github-image {
    width: 2rem;
    height: 2rem;
  }
}

@include color-mode(dark) {
  .navigation {
    background: var(--bs-tertiary-bg);
  }
  .breadcrumb-link {
    &:hover, &:focus {
      color: $navbar-dark-hover-color;
    }
  }

  .bg-gradient-2 {
    background: linear-gradient(180deg, rgba(0, 40, 114, 0) 0%, rgba(116, 165, 255, 0.04) 100%);
  }

  .landing-block {
    background: linear-gradient(to bottom, $body-bg-dark, lighten($body-bg-dark, 4));
  }

  .dropdown-menu {
    box-shadow: 0 1.417rem 3.167rem 0 rgba(0, 0, 0, 0.4),
      0 5.75rem 5.75rem 0 rgba(0, 0, 0, 0.1),
      0 12.917rem 7.75rem 0 rgba(0, 0, 0, 0.05),
      0 22.917rem 9.167rem 0 rgba(0, 0, 0, 0.01),
      0 35.833rem 10rem 0 rgba(0, 0, 0, 0);
  }

  .pane-shadow {
    box-shadow: 0 .167rem .417rem 0 rgba(0, 0, 0, 0.1),
      0 .75rem .75rem 0 rgba(0, 0, 0, 0.1),
      0 1.583rem 1rem 0 rgba(0, 0, 0, 0.1),
      0 2.833rem 1.167rem 0 rgba(0, 0, 0, 0),
      0 4.5rem 1.25rem 0 rgba(0, 0, 0, 0);
  }
}

/* ---------------------------- */
/* The markdown related styles */
/* Light theme. */

:root {
  --color-canvas-default: #ffffff;
  --color-canvas-subtle: #f6f8fa;
  --color-border-default: #d0d7de;
  --color-border-muted: hsla(210, 18%, 87%, 1);
}

/* Dark theme. */
@include color-mode(dark) {
  #root {
    --color-canvas-default: $body-bg-dark;
    --color-canvas-subtle: #212529;
    --color-border-default: #495057;
    --color-border-muted: #21262d;
  }
  .markdown {
    .anchor {
      text-decoration: none;
      color: $body-color-dark;
    }
  }

  .tooltip {
    --bs-tooltip-bg: #3A3A3A;
    --bs-tooltip-color: #FFFFFF;
    --bs-tooltip-opacity: 1;
  }
}

.markdown {
  table {
    border-spacing: 0;
    border-collapse: collapse;
    display: block;
    margin-top: 0;
    margin-bottom: 16px;
    width: max-content;
    max-width: 100%;
    overflow: auto;
  }

  tr {
    background-color: var(--color-canvas-default) !important;
    border-top: 1px solid var(--color-border-muted);
  }

  tr:nth-child(2n) {
    background-color: var(--color-canvas-subtle) !important;
  }

  td, th {
    padding: 6px 13px;
    border: 1px solid var(--color-border-default);
  }

  th {
    font-weight: 600;
    background-color: var(--color-canvas-subtle) !important;
  }

  table img {
    background-color: transparent;
  }

  div.tab-content {
    max-width: 100%;
  }

  div.tab-content img {
    max-width: 100%;
  }

  @for $i from 1 through 6 {
    h#{$i} {
      word-wrap: break-word;
      margin-top: 2rem;
      margin-bottom: 1.333rem;
      padding-bottom: 0.3em;
      border-bottom: 1px solid var(--bs-border-color);
      &:first-child {
        margin-top: 0;
      }
    }
  }

  li {
    padding-bottom: .292em;
    padding-top: .292em;
  }

  img {
    max-width: 100%;
  }

  .anchor {
    text-decoration: none;
    color: $body-color;

    &:hover::before {
      content: url('../public/images/link.svg');
      margin-left: -1.4rem;
      font-size: 1em;
      vertical-align: middle;
      position: absolute;
      display: inline-block;
      transition: opacity .1s ease;
    }
  }

  div:has(> code[class^="language-"]) {
    border-radius: 1rem !important;
    border: .083rem solid rgba(231, 234, 241, 1) !important;
    background-color: rgba(250, 250, 250, 1) !important;
  }

  h1 {
    font-size: 1.86rem;
    line-height: 2.42rem;
  }

  h2 {
    font-size: 1.42rem;
    line-height: 1.86rem;
  }

  h3 {
    font-size: 1.29rem;
    line-height: 1.72rem;
  }

  h4 {
    font-size: 1.14rem;
    line-height: 1.42rem;
  }
}

@include color-mode(dark) {
  .markdown div:has(> code[class^="language-"]) {
    border: .083rem solid rgba(54, 58, 65, 1) !important;
    background-color: rgba(35, 36, 38, 1) !important;
  }
}
/* End of the markdown related styles */
/* ---------------------------- */

.version-table {
  td, th {
    padding: .833rem 1.667rem;
  }

  > tbody > tr:hover > * {
    --bs-table-bg-state: var(--color-canvas-subtle);
  }

  > tbody tr:last-child {
    border-color: transparent;
  }
}

.graph {
  text {
    font-size: 0.6em;
  }

  .graph-substrate {
    opacity: 1%;
  }

  &:has(.graph-col-hover:hover), &:has(.graph-substrate:hover) {
    .graph-substrate {
      opacity: 5%;
    }
    .graph-col {
      opacity: 35%;
    }
  }

  .graph-col {
    opacity: 85%;

    &:has(+ .graph-col-hover:hover) {
      opacity: 100%;
      fill: var(--bs-body-color);
    }
  }

  .graph-col-hover {
    opacity: 0%;

    &:hover {
      opacity: 8% !important;
      fill: var(--bs-body-color);

      + text {
        visibility: visible;
        color: var(--bs-body-color);
      }
    }
  }


}

.forks-list {
  margin-left: calc(-1*(2.286rem + 0.083rem));
  margin-right: calc(-1*(2.286rem + 0.083rem));

  .rounded-pane-middle:last-of-type {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@include media-breakpoint-down(lg) {
  .hide-less-lg {
    display: none;
  }
}

.global-search-menu {
  max-height: 480px;
  overflow-y: auto;
}

@include media-breakpoint-up(lg) {
  .landing-block {
    .text-block {
      h1 {
        font-size: 48px;
      }
    }
  }

  .global-search-menu {
    min-width: 640px;
  }
}

.nowrap {
  white-space: nowrap;
}

.document-search {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1em;
  position: relative;

  .global-search-icon {
    position: absolute;
    margin-left: 0.5em;
    margin-top: 0.5em;
    width: 1.25em;
    height: 1.25em;
  }

  .search {
    padding-left: 2.3em;
    font-size: 1em;
  }
}

.filtersBlock {
  min-height: 4.15rem;
}

@include color-mode(light) {
  .global-search-start {
    .search {
      background-color: #fff;
    }
  }
}

.favorite {
  @extend .text-secondary;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 0.5714rem;
  align-items: center;
  padding: 0.5714rem 0.8571rem 0.5714rem 1rem;

  @include media-breakpoint-down(lg) {
    grid-column-gap: 0;
    padding-left: 0.8571rem;
    padding-right: 0.8571rem;
  }

  cursor: pointer;
  border-radius: 0.5714rem;
  border: solid 1px var(--bs-border-color);
  height: 2.5715rem;

  font-size: 0.8571rem;

  .react-icons {
    height: 1.429rem;
    width: 1.429rem;
    padding: 0;
    margin: 0;
    color: #266CED;
  }
}

.favoriteCount {
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 0.929rem 1.1429rem 0.929rem 0.8571rem;
  height: 3.5714rem;
  grid-column-gap: 0.643rem;

  font-size: 1rem;
  cursor: pointer;
  border-radius: 1.1429rem;
  border: solid 1px var(--bs-border-color);

  &.selected {
    color: var(--bs-link-accent-color);
  }

  .react-icons {
    height: 1.714rem;
    width: 1.714rem;
    padding: 0;
    margin: 0;
    color: #266CED;
  }

  .count {
    padding: 0.1429rem 0.5rem;
    border-radius: 1.714rem;
    background-color: var(--bs-tertiary-bg);
  }
}

.platformIcon {
  width: 0.86rem;
  height: 0.86rem;
}

.tooltip {
  border-radius: 0.574rem;
  font-weight: bold;
  .tooltip-inner {
    padding: 1.286rem;
    font-size: 0.857rem;
    min-width: 23rem;
  }
}

.filter-body {
  display: flex;
  flex-direction: column;

  border-radius: 1.143rem;
  border: 1px solid var(--bs-border-color);

  overflow: hidden;
}

.filter-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.2rem;
}

.widget-header-container {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-column-gap: 0.4rem;
}

.entity-card-header {
  &.h4 {
    line-height: 2.5714rem;
    margin: 0;
  }
}


@include media-breakpoint-down(lg) {
  .one-badge {
    column-gap: 0;

    .one-badge-title {
      display: none;
    }
  }
}
