/* customization is here */

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/variables";

$enable-negative-margins: true;

$link-color: rgba(38, 108, 237, 1);
$link-hover-color: rgba(68, 134, 255, 1);
$link-color-dark: $link-color;
$link-hover-color-dark: $link-hover-color;

$body-tertiary-bg-dark: rgba(33, 34, 36, 1);

$body-bg-dark: rgba(27, 27, 27, 1);
$font-weight-base: 500;
$font-weight-bold: 700;

$border-width: 1px;
$border-color: rgba(231, 234, 241, 1);
$border-color-dark: rgba(54, 58, 65, 1);
$border-radius: 1em;

$input-border-width: 0;
$input-bg: rgba(246, 246, 246, 1);
$input-focus-bg: $input-bg;
$input-bg-dark: black;
$input-focus-bg-dark: $input-bg-dark;
$input-padding-x: .917em;
$input-padding-y: .917em;

$form-select-bg: $input-bg;
$form-select-border-width: $input-border-width;
$form-select-indicator-padding: 2.75em;
$form-select-padding-x: $input-padding-x;
$form-select-padding-y: $input-padding-y;
$form-select-bg-dark: $input-bg-dark;

$btn-padding-x: 1.667em;
$btn-padding-y: .833em;
$btn-padding-x-sm: .833em;
$btn-padding-y-sm: .417em;

$dropdown-item-padding-x: 1.667em;
$dropdown-item-padding-y: .417em;

$nav-link-padding-x: 1.667em;
$nav-link-padding-y: 1em;
$nav-tabs-border-radius: calc(var(--bs-border-radius) * 2);

$card-inner-border-radius: calc(var(--bs-border-radius) * 2);
$card-border-color: var(--bs-border-color);

$dropdown-divider-bg: var(--bs-border-color);

/* import bootstrap to set changes */
@import "../node_modules/bootstrap/scss/bootstrap";

:root {
  --bs-link-accent-color: rgba(38, 108, 237, 1);

  /* Alerts */
  --bs-info-bg-subtle: rgba(129, 164, 255, 0.1);
  --bs-info-border-subtle: rgba(38, 61, 237, 0.6);
  --bs-info-text-emphasis: rgba(38, 108, 237, 1);

  --bs-warning-bg-subtle: rgba(255, 220, 129, 0.1);
  --bs-warning-border-subtle: rgba(237, 180, 38, 0.6);
  --bs-warning-text-emphasis: rgba(218, 157, 4, 1);

  --bs-danger-bg-subtle: rgba(255, 129, 129, 0.1);
  --bs-danger-border-subtle: rgba(241, 50, 50, 0.6);
  --bs-danger-text-emphasis: rgba(241, 50, 50, 1);

  --bs-border-radius-sm: 0.8rem;
}

.dropdown-item:active {
  --bs-dropdown-link-active-bg: rgba(246, 247, 250, 1);
  --bs-dropdown-link-active-color: var(--bs-link-accent-color);
}

.card-header {
  --bs-card-border-color: var(--bs-border-color);
}

@include color-mode(dark) {
  #root {
    --bs-link-accent-color: rgba(43, 118, 254, 1);
  }

  .dropdown-item:active {
    --bs-dropdown-link-active-color: var(--bs-link-accent-color);
    --bs-dropdown-link-active-bg: rgba(35, 36, 38, 1);
  }

  .form-control, select {
    background-color: $input-bg-dark;

    &:focus {
      background-color: $input-focus-bg-dark;
    }
  }
}

@each $state in map-keys($theme-colors) {
  .alert-#{$state} {
    border: none;
    border-radius: 0;
    border-left-width: .25rem;
    border-left-style: solid;
    color: var(--bs-body-color);
    border-color: var(--bs-#{$state}-border-subtle);
    background-color: var(--bs-#{$state}-bg-subtle);

    .alert-heading {
      font-weight: bold;
      color: var(--bs-#{$state}-text-emphasis);
      border: none;
    }
  }
}


