.one-badge {
  display: grid;
  grid-template-columns: 1.715rem auto;
  align-items: center;
  margin: 0.574rem 0.574rem 0.574rem 0;

  padding: 0.4286rem 0.574rem;
  column-gap: 0.4286rem;
  border-radius: 0.574rem;
  border: solid 1px;
  height: 2.572rem;

  cursor: pointer;
  text-transform: uppercase;

  .one-badge-title {
    font-size: 0.7857rem;
    font-weight: bold;
    letter-spacing: 0.0357rem;
  }

  &.downloads {
    border-color: #3176FF42;
    background-color: #3176FF0A;
  }

  &.stars {
    border-color: #AC8C1A5E;
    background-color: #FFDE310A;
  }

  &.forked {
    border-color: #CD62FF42;
    background-color: #C931FF0A;
  }

  &.keepItRegular {
    border-color: #3176FF42;
    background-color: #3176FF0A;
  }

  &.risenFromDead {
    border-color: #8B8B8B42;
    background-color: #9D9D9D17;
  }

  &.abandoned {
    border-color: #8B8B8B42;
    background-color: #9D9D9D17;
  }

  &.oneManWolfPack {
    border-color: #8B8B8B42;
    background-color: #9D9D9D17;
  }

  &.ridinSolo {
    border-color: #AC8C1A5E;
    background-color: #FFDE310A;
  }
}

.all-badges {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.574rem;
}

.badge-icon {
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  background-image: url(./sprite.png);
  background-size: 240px 24px;

  &.downloads {
    background-position: 0 0;
  }

  &.stars {
    background-position: 216px 0;
  }

  &.forked {
    background-position: 192px 0;
  }

  &.keepItRegular {
    background-position: 48px 0;
  }

  &.risenFromDead {
    background-position: 24px 0;
  }

  &.abandoned {
    background-position: 120px 0;
  }

  &.oneManWolfPack {
    background-position: 96px 0;
  }

  &.ridinSolo {
    background-position: 72px 0;
  }
}

.badges-filter {

  .badge-filter {
    display: grid;
    row-gap: 0.8571rem;
    padding: 0.8571rem;
    border-top: 1px solid var(--bs-border-color);

    &:first-of-type {
      border-top: none;
    }

    &.single-value {
      cursor: pointer;
    }

    &.hover:hover,
    .hover:hover {
      background-color: var(--bs-tertiary-bg);
    }

    &.selected {
      background-color: var(--bs-tertiary-bg);
    }

    .title {
      display: grid;
      align-items: center;
      grid-template-columns: 24px 1fr 1rem;
      column-gap: 0.5714rem;

      .selected-icon {
        width: 1rem;
        height: 1rem;
      }

      &.selected {
        color: var(--bs-link-accent-color);
      }
    }

    .multi-values {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .multi-value {
      padding: 0.5714rem 1rem;
      margin-right: 0.2857rem;
      margin-bottom: 0.2857rem;
      border: 1px solid var(--bs-border-color);
      border-radius: 0.8571rem;
      cursor: pointer;

      &.selected {
        background-color: var(--bs-link-accent-color);
        border-color: var(--bs-link-accent-color);
        color: white;
      }
    }
  }
}
